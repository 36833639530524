
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="54" height="10" viewBox="0 0 54 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M54 5L46.5 0.669869L46.5 9.33012L54 5ZM6.55671e-08 5.75L24.7669 5.75L24.7669 4.25L-6.55671e-08 4.25L6.55671e-08 5.75ZM24.7669 5.75L47.25 5.75L47.25 4.25L24.7669 4.25L24.7669 5.75Z" fill="url(#paint0_linear_16292_203834)"/>
<defs>
<linearGradient id="paint0_linear_16292_203834" x1="2.25" y1="5.5" x2="54" y2="5.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#5B1FD3"/>
<stop offset="1" stop-color="#44179D"/>
</linearGradient>
</defs>
</svg>

  </template>

  <script>
  export default {
    name: 'ClaimTodoClosedSolidArrow',
    inheritAttrs: true,
  }
  </script>
